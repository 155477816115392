@use '@/styles/utils/mixins.scss' as *;

.jobCard {
  height: 100%;
  width: 100%;
  border-radius: 1.25rem;
  -webkit-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
  -moz-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
  box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
  padding: 2.5rem 1.875rem;
  display: flex;
  flex-direction: column;
  border: 0.063rem solid $white;
  background-color: $white;
  transition: all 0.2s ease-in-out;

  &:hover {
    -webkit-box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
    -moz-box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
    box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
    border-color: $primaryAlt;
    cursor: pointer;
  }

  @include tab() {
    width: 100%;
    border-color: $primaryAlt;
  }

  .jobCardBody {
    display: flex;
    flex-direction: column;
    gap: 6.25rem;
    justify-content: space-between;
    height: 100%;

    .textWrapper {
      h2 {
        margin-bottom: 1.25rem;
        font-weight: 300;
      }

      .jobLocation,
      .jobDate {
        display: flex;
        flex-direction: row;
        gap: 0.625rem;
        align-items: center;
        margin-top: 1.25rem;

        svg {
          width: 1.25rem;
          height: 1.25rem;
        }

        p {
          font-size: 1rem;
          font-weight: 300;
        }
      }
    }

    .description {
      color: $neutral08;
    }
  }
}
